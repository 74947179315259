<template>
  <section class="section">
    <div class="container">
      <back-button to="LandingPage"/>
      <h1 class="title has-text-centered">File shared with you</h1>
      <template v-if="file">
        <div class="columns is-centered pt-4">
          <div class="column is-half is-full-mobile">
            <file :file="file" :show-delete="false"/>
          </div>
        </div>
      </template>

      <template v-else-if="fileNotFound">
        <div class="notification is-danger is-light has-text-centered">
          File with slug "{{ slug }}" not found. Is the slug correct?
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import UploadResultModal from "../components/modals/UploadResultModal";
import BackButton from "../components/BackButton";
import File from "../components/File";

export default {
  name: "ViewFile",
  components: {File, BackButton, UploadResultModal},
  props: {
    slug: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      file: null,
      fileNotFound: false
    }
  },
  created() {
    this.$store.commit('setIsLoading', true);
    this.$http.get(`/files/${this.slug}`).then(res => {
      this.file = res.data.data;
      this.$store.commit('setIsLoading', false);
    }).catch(() => {
      this.fileNotFound = true;
      this.$store.commit('setIsLoading', false);
    });
  }
}
</script>

<style scoped>

</style>
